<template>
  <div class="container">
    <h1>Ste prihlásený</h1>
    <p>Meno: {{ $store.getters.userData.name }}</p>
    <p>Provider: {{ $store.getters.userData.provider }}</p>
    <p>ID: {{ $store.getters.userData.id }}</p>
    <BaseButton @click="logout()">
      Odhlásiť
    </BaseButton>
  </div>
</template>

<script>
import axios from 'axios';

import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'Account',
  components: {
    BaseButton,
  },
  data() {
    return {
      urls: {
        logout: {
          auth0: `${process.env.VUE_APP_AUTH_BASE}/logout/auth0`,
          discord: `${process.env.VUE_APP_AUTH_BASE}/logout/discord`,
        },
      },
    };
  },
  methods: {
    logout() {
      this.$store.commit('setUserToken', null);
      axios.get('this.urls.logout.auth0');
      this.$router.push({ name: 'About' });
    },
  },
};
</script>

<style>

</style>
