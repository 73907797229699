<template>
  <div class="contaienr p404">
    <h1 class="title">Táto stránka sa nenašla</h1>
    <BaseButton @click="$router.push({ name: 'About' })">
      Naspäť na hlavnú stránku
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: '404',
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
.p404 {
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
  .title {
    margin-bottom: 3rem;
  }
}
</style>
